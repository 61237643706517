exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acceptable-use-mdx": () => import("./../../../src/pages/acceptable-use.mdx" /* webpackChunkName: "component---src-pages-acceptable-use-mdx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-mdx": () => import("./../../../src/pages/cookies.mdx" /* webpackChunkName: "component---src-pages-cookies-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-mdx": () => import("./../../../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-tos-mdx": () => import("./../../../src/pages/tos.mdx" /* webpackChunkName: "component---src-pages-tos-mdx" */)
}

